import { ListItemModel, ModelProps } from './typesListItem';
import _ from 'lodash';

import styles from './styles.module.css';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  SEND_SUCCESS_VERIFICATION_MODEL_EMAIL,
  STORE_CHAT_MODEL_MUTATION,
} from '../chatModel';
import { ModelStatusEnum } from '../../types';
import { getModelActivity, ModelActivityEnum } from '../../helpers';
import {
  formatDateInterval,
  getStringDate,
  getTotalTime,
} from 'src/utils/time.utils';
import { getCollectingStatusIcon } from 'src/utils/icons.utils';
import { Button, Tag, Typography } from 'antd';
import Table, { CustomColumnType } from '../table';

const { Paragraph } = Typography;

export const AccountsList = ({
  data,
  title,
  companyEmail,
  handleUpdateUsersData,
}: {
  data: ListItemModel[];
  title: string;
  companyEmail: string;
  handleUpdateUsersData: (newData: ModelProps) => void;
}) => {
  const createData = (
    name: string,
    email: string,
    confirmTryCount: number,
    status: string,
    confirmedAt: string | null,
    contactsParsed: string,
    id: string,
    canEarn: boolean,
    settings: {
      totalContactsToCollecting: number | null;
      contactsCollected: number | null;
      totalTransactionsToCollecting: number | null;
      transactionsCollected: number | null;
      totalVaultToCollecting: number | null;
      vaultCollected: number | null;
      contactsCollectingStartAt: string | null;
      transactionsCollectingStartAt: string | null;
      vaultCollectingStartAt: string | null;
      transactionsCollectingEndAt: string | null;
      contactsCollectingEndAt: string | null;
      vaultCollectingEndAt: string | null;
      updatedAt: string | null;
      accessProvidedAt: string | null;
      confirmationEmailSentAt: string | null;
      lastOnlineAt: string | null;
    } | null,
    campaignsStats?: {
      totalSubscribers: number;
      campaignCount: number;
    },
  ) => ({
    key: id,
    name,
    email,
    confirmTryCount,
    status,
    confirmedAt,
    contactsParsed,
    id,
    settings,
    canEarn,
    campaignsStats,
  });

  const [storeChatModel, { data: chatModeldata, loading }] = useMutation<any>(
    STORE_CHAT_MODEL_MUTATION,
  );

  const [sendModelAccessProvidedToTheAppEmail] = useMutation<{
    sendModelAccessProvidedToTheAppEmail: boolean;
  }>(SEND_SUCCESS_VERIFICATION_MODEL_EMAIL);

  const rows = data.map((i: ListItemModel) => {
    return createData(
      i.name,
      i.email,
      i.confirmTryCount,
      i.status,
      i.confirmedAt,
      `${_.get(i, 'setting.contactsCollected', '0')} / ${_.get(
        i,
        'setting.totalContactsToCollecting',
        '0',
      )}`,
      i.id,
      i.canEarn,
      i.setting,
      i.campaignsStats,
    );
  });

  const approveModel = async (id: string) => {
    await storeChatModel({
      variables: {
        input: {
          id,
          status: ModelStatusEnum.ACTIVE,
        },
      },
    });

    await sendModelAccessProvidedToTheAppEmail({
      variables: { input: { email: companyEmail, modelId: id } },
    });
  };

  useEffect(() => {
    if (chatModeldata) {
      handleUpdateUsersData(chatModeldata.storeChatModel);
    }
  }, [chatModeldata]);

  const COLUMNS: CustomColumnType = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
      align: 'left',
      render: (__: any, node?: any) => {
        return (
          <div className="flex min-w-[100px]">
            {!node.canEarn && (
              <span className="flex items-center text-red-700 mr-1.5">
                [&#9940;]
              </span>
            )}{' '}
            {node.name}
          </div>
        );
      },
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Creator id',
      width: '120px',
      align: 'right',
    },
    {
      key: 'campaignsFans',
      dataIndex: 'campaignsFans',
      title: 'Campaigns fans',
      align: 'right',
      width: '100px',
      render: (__: any, node: any) => {
        return node.campaignsStats?.totalSubscribers || 0;
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
      align: 'right',
    },
    {
      key: 'confirmTryCount',
      dataIndex: 'confirmTryCount',
      title: 'Try to confirm',
      width: '100px',
      align: 'right',
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      align: 'right',
      render: (__: any, node: any) => {
        return <Tag color="processing">{node.status}</Tag>;
      },
    },
    {
      key: 'activity',
      dataIndex: 'activity',
      title: 'Activity',
      align: 'right',
      render: (__: any, node: any) => {
        return node.settings && node.status === 'ACTIVE' ? (
          getModelActivity(node.settings.updatedAt || null) ===
          ModelActivityEnum.ACTIVE ? (
            <Tag color="success">ACTIVE</Tag>
          ) : getModelActivity(node.settings.updatedAt || null) ===
            ModelActivityEnum.NOT_ACTIVE ? (
            <Tag color="warning">NOT ACTIVE</Tag>
          ) : getModelActivity(node.settings.updatedAt || null) ===
            ModelActivityEnum.LOST ? (
            <Tag color="error">LOST</Tag>
          ) : (
            ''
          )
        ) : (
          'n/a'
        );
      },
    },
    {
      key: 'confirmedAt',
      dataIndex: 'confirmedAt',
      title: 'Date of confirm',
      width: '100px',
      align: 'right',
      render: (__: any, node: any) => {
        return getStringDate(node.confirmedAt) || '-';
      },
    },
    {
      key: 'parsedData',
      dataIndex: 'parsedData',
      title: 'Parsed data',
      align: 'right',
      render: (__: any, node: any) => {
        return (
          <>
            <div>
              Contacts: {node.settings?.contactsCollected || '-'} /{' '}
              {node.settings?.totalContactsToCollecting || '-'}{' '}
              {getCollectingStatusIcon(
                node.settings?.contactsCollectingStartAt || null,
                node.settings?.contactsCollectingEndAt || null,
              )}
            </div>
            <div>
              Vault: {node.settings?.vaultCollected || '-'} /{' '}
              {node.settings?.totalVaultToCollecting || '-'}{' '}
              {getCollectingStatusIcon(
                node.settings?.vaultCollectingStartAt || null,
                node.settings?.vaultCollectingEndAt || null,
              )}
            </div>
            <div>
              Transactions: {node.settings?.transactionsCollected || '-'}{' '}
              {getCollectingStatusIcon(
                node.settings?.transactionsCollectingStartAt || null,
                node.settings?.transactionsCollectingEndAt || null,
              )}
            </div>
          </>
        );
      },
    },
    {
      key: 'parsedTime',
      dataIndex: 'parsedTime',
      title: 'Parsing time',
      align: 'right',
      render: (__: any, node: any) => {
        return (
          <>
            <div>
              Contacts:{' '}
              {formatDateInterval(
                node.settings?.contactsCollectingStartAt,
                node.settings?.contactsCollectingEndAt,
              ) || '-'}
            </div>
            <div>
              Vault:{' '}
              {formatDateInterval(
                node.settings?.vaultCollectingStartAt,
                node.settings?.vaultCollectingEndAt,
              ) || '-'}
            </div>
            <div>
              Transactions:{' '}
              {formatDateInterval(
                node.settings?.transactionsCollectingStartAt,
                node.settings?.transactionsCollectingEndAt,
              ) || '-'}
            </div>
            <div>
              Total:{' '}
              {getTotalTime(
                [
                  node.settings?.contactsCollectingStartAt,
                  node.settings?.transactionsCollectingStartAt,
                ].filter(Boolean) as [string],
                [
                  node.settings?.contactsCollectingEndAt,
                  node.settings?.transactionsCollectingEndAt,
                ].filter(Boolean) as [string],
              ) || '-'}
            </div>
          </>
        );
      },
    },
    {
      key: 'lastSeen',
      dataIndex: 'lastSeen',
      title: 'Last seen',
      align: 'right',
      render: (__: any, node: any) => {
        return getStringDate(node.settings?.lastOnlineAt, true) || '-';
      },
    },
    {
      key: 'welcomeEmail',
      dataIndex: 'welcomeEmail',
      title: 'Welcome email',
      width: '100px',
      align: 'right',
      render: (__: any, node: any) => {
        return (
          getStringDate(node.settings?.confirmationEmailSentAt, true) || '-'
        );
      },
    },
    {
      key: 'accessProvidedAt',
      dataIndex: 'accessProvidedAt',
      title: 'Access provided',
      width: '100px',
      align: 'right',
      render: (__: any, node: any) => {
        if (node.status === 'CONFIRMED') {
          return (
            <Button type="primary" onClick={() => approveModel(node.id)}>
              Provide access
            </Button>
          );
        }

        if (node.status === 'ACTIVE' && node.settings?.accessProvidedAt) {
          return getStringDate(node.settings.accessProvidedAt);
        }

        return '';
      },
    },
  ];

  return (
    <div className={styles.accountsListContainer}>
      <Paragraph style={{ fontSize: '1rem' }} underline>
        {title}:
      </Paragraph>
      <Table columns={COLUMNS} data={rows} noScroll loading={loading} />
    </div>
  );
};
